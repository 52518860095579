import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CountryPage from "./page/countryPage/CountryPage";
import HomePage from "./page/homePage/HomePage";
import MethodPage from "./page/methodPage/MethodPage";
import ReceiverInfoPage from "./page/receiverInfoPage/ReceiverInfoPage";
import ConfirmInfoPage from "./page/confirmInfoPage/ConfirmInfoPage";
import ResultPage from "./page/result/ResultPage";
import ErrorPage from "./page/errorPage/ErrorPage";
import Header from "./components/header/Header";
import _404Page from "./page/_404Page";

import { setTradeInfo } from "./redux/actions";
import { PuffLoader } from "react-spinners";
import { getQueryVariable, notify, clearSessionStorage } from "./utils/config";
import $axios from "./utils/http";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App(props) {
  const [isLoading, setIsloading] = useState(true);

  const { t } = useTranslation();
  function setLiveChat(data) {
    //live-chat
    window.__lc = window.__lc || {};
    window.__lc.license = 8514118;
    window.__lc.chat_between_groups = false;
    window.__lc.group = 23;
    window.__lc.params = [
      { name: "app Name", value: data?.app_info?.app_name },
      { name: "Trade NO.", value: data?.out_trade_no },
      { name: "Order Amount", value: data?.order_amount },
    ];
    var lc = document.createElement("script");
    lc.type = "text/javascript";
    lc.async = true;
    lc.src =
      ("https:" === document.location.protocol ? "https://" : "http://") +
      "cdn.livechatinc.com/tracking.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(lc, s);
    //end live-chat
  }

  const [errorMsg, setErrorMsg] = useState("invalid token");
  useEffect(() => {
    if (!getQueryVariable("t")) {
      notify("error", t("form.missToken"));
    } else {
      $axios({
        url: "/api/internal/checkout/detail",
        method: "POST",
      })
        .then((res) => {
          //console.log("res", res);
          if (!!res && res.code === 200) {
            setLiveChat(res.data);
            props.setTradeInfo(res.data);
            // 将所有数据处理完之后再将路由阀门打开
            setIsloading(false);
            setErrorMsg("");
          }
        })
        .catch((err) => {
          if (err.code === 4004002 || err.code === 4004001) {
            // token 无效或失效
            setIsloading(false);
            setErrorMsg(err.msg);
            clearSessionStorage();
          }
          notify("error", err.msg);
        });
    }
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isLoading && (
        <div className="app__loader">
          <div className="loader">
            <PuffLoader color="#01b88f" />
          </div>
        </div>
      )}
      {!isLoading && !errorMsg && (
        <div className="App">
          <Router>
            {/* 独占路由 */}
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/choose-country" component={CountryPage} />
              <Route path="/choose-method" component={MethodPage} />
              <Route path="/fill-info" component={ReceiverInfoPage} />
              <Route path="/confirm-info" component={ConfirmInfoPage} />
              <Route path="/result" component={ResultPage} />
              <Route component={_404Page} />
            </Switch>
          </Router>
        </div>
      )}
      {!isLoading && errorMsg && (
        <div className="App">
          <Header />
          <ErrorPage msg={errorMsg} />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
