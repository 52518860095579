import React from "react";
import ErrorIcon from "../../images/error.png";
import "./ErrorPage.scss";

function ErrorPage(props) {
  return (
    <div className="error-wrap">
      <div className="tip-box">
        <img src={ErrorIcon} className="icon" width="50px" alt="" />
        <p className="tip">{props.msg}</p>
      </div>
    </div>
  );
}

export default ErrorPage;
