const getUrl = () => {
  switch (window.location.hostname) {
    case "payout-h5.pagsmile.com":
      return "https://api.transfersmile.com/";

    case "dev-payout-h5.pagsmile.com":
      return "https://sandbox.transfersmile.com/";

    case "sandbox-payout-h5.pagsmile.com":
      return "https://sandbox.transfersmile.com/";

    case "localhost":
      return "https://sandbox.transfersmile.com/";

    default:
      return "https://sandbox.transfersmile.com/";
  }
};

export const url = getUrl();
