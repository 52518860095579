import { useField } from "formik";
import { TextField as MaterialTextField } from "@material-ui/core";
import { _format, _formatCpf, _formatCnpj } from "../../utils/config";
import "./Form.scss";

function FormInput({ label, ...props }) {
  const [field, meta] = useField(props);
  //console.log("field",field)
  const dealedValue = (value) => {
    if (props.name === "pixKey") {
      if (!props.id) {
        return value;
      } else if (props.id === "CPF") {
        return _formatCpf(value);
      } else if (props.id === "CNPJ") {
        return _formatCnpj(value);
      } else {
        return value;
      }
    } else if (props.name.indexOf("Cpf_cnpj") >= 0) {
      return _format(value);
    } else {
      return value;
    }
  };
  return (
    <div className="form-box">
      <MaterialTextField
        //error={meta.touched && meta.error}
        {...{ ...field, value: dealedValue(field.value) }}
        {...props}
        autoComplete="off"
        label={props.require ? `* ${label}` : label}
        variant="outlined"
      />
      {meta.touched && meta.error ? (
        <div className="form-error">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default FormInput;
