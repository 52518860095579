import React from "react";
import { useTranslation } from "react-i18next";
import CloseImg from "../../images/close_gray.png";
import WarningImg from "../../images/warning.png";
import "./Notice.scss";

function Notice({ show, text, setNoticeHide }) {
  const { t } = useTranslation();

  return (
    <div className="home__notice" style={{ display: show ? "block" : "none" }}>
      <div className="home__notice__warning">
        <img
          className="home__notice__warningClose"
          src={CloseImg}
          alt=""
          onClick={() => {
            setNoticeHide();
          }}
        />
        <img src={WarningImg} width="70" alt="" />
        <p className="home__notice__warningText">{text}</p>
        <button
          className="home__notice__warningButton"
          onClick={() => {
            setNoticeHide();
          }}
        >
          {t("okay")}
        </button>
      </div>
    </div>
  );
}

export default Notice;
