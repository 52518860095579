import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import "./ResultPage.scss";

import Button from "@material-ui/core/Button";
import successImg from "../../images/successful.png";
import processingImg from "../../images/processing.png";
import errorImg from "../../images/error.png";
import cancelImg from "../../images/cancel.png";
import $axios from "../../utils/axios";
import { getSessionStorage } from "../../utils/config";
import { useTranslation } from "react-i18next";

function ResultPage() {
  const { t } = useTranslation();
  const backUrl = getSessionStorage("_od")?.return_url;
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const handleTry = () => {
    window.location.href = backUrl;
  };
  useEffect(() => {
    $axios({
      url: "/api/internal/checkout/status",
      method: "POST",
    })
      .then((res) => {
        if (res.code === 200) {
          setStatus(res.data.status);
          if (res.data.status === "REJECTED" || res.data.status === "CLOSED") {
            setDescription(res.data.description);
          }
        }
      })
      .catch((err) => {
        //console.log("err", err);
        if (err.code === 4004002 || err.code === 4004001) {
          setStatus("TimeEND");
        }
      });
  }, []);

  return (
    <div className="result-wrap">
      <Header />
      <div className="result-box">
        <div className="result1">
          <div className="result-status">
            {(status === "PROCESSING" || status === "IN_PROCESSING") && (
              <>
                <img src={processingImg} alt="" />
                <p className="result-text">{t("transation_processing")}</p>
              </>
            )}
            {(status === "REJECTED" || status === "CLOSED") && (
              <>
                <img src={errorImg} alt="" />
                <p className="result-text">{t("transaction_failed")}</p>
                <p className="result-error">Reason:{description}</p>
              </>
            )}
            {status === "PAID" && (
              <>
                <img src={successImg} alt="" />
                <p className="result-text">{t("transaction_success")}</p>
              </>
            )}
            {status === "TimeEND" && (
              <>
                <img src={cancelImg} alt="" />
                <p className="result-text">{t("transaction_cancelled")}</p>
                <p className="tip2">{t("cancelled_tip1")}</p>
              </>
            )}
          </div>

          {status && (
            <div className="tip-box">
              <p className="tip1">{t("result_tip1")}</p>
              <p className="tip2">
                {t("result_tip2")}{" "}
                <a href="https://www.pagsmile.com">https://www.pagsmile.com</a>
              </p>
            </div>
          )}

          {status === "PAID" && (
            <div>
              <Button variant="contained" color="primary" onClick={handleTry}>
                {t("paid_tip1")}
              </Button>
              {/* <Button
                className="_blue"
                variant="contained"
                color="primary"
                onClick={handleTry}
              >
                {t("paid_tip2")}
              </Button> */}
            </div>
          )}
          {(status === "PROCESSING" ||
            status === "IN_PROCESSING" ||
            status === "REJECTED" ||
            status === "CLOSED" ||
            status === "TimeEND") && (
            <div>
              <Button variant="contained" color="primary" onClick={handleTry}>
                {t("try_again")}
              </Button>
              <Button
                onClick={() => {
                  window?.LC_API?.open_chat_window();
                }}
              >
                {t("help")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultPage;
