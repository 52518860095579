const formValidateEn = {
  "form.chooseCountry": "Choose final country",
  "form.missToken": "Missing parameters!",
  "form.formSections.phone": "PHONE",
  "form.formSections.email": "EMAIL",
  "form.formSections.checking": "CHECKING",
  "form.formSections.savings": "SAVINGS",
  "form.formSections.debit": "DEBIT",
  "form.formValidate.invalidName": "Invalid Name",
  "form.formValidate.selectBank": "Please select a bank",
  "form.formValidate.selectDocumentType": "Please select a document type",
  "form.formValidate.invalidId": "Invalid Id",
  "form.formValidate.selectAccountType": "Please select a account type",
  "form.formValidate.invalidAccount": "Invalid Account",
  "form.formValidate.invalidEmailAddress": "Invalid e-mail addresss",
  "form.formValidate.inputEmail": "Please input your e-mail",
  "form.formValidate.inputCellphone": "Please input a cellphone",
  "form.formValidate.invalidCellphone": "Invalid Cellphone",
  "form.formValidate.inputCpfOrCnpj": "Please input your cpf or cnpj",
  "form.formValidate.invalidCpfOrCnpj": "Invalid CPF/CNPJ",
  "form.formValidate.selectCountry": "Please select a country",
  "form.formValidate.inconsistent": "The accounts are inconsistent",
  "form.formValidate.selectKeyType": "Please select a key type",
  "form.formValidate.fillPixKey": "Please fill in the Pix Key",
  "form.formValidate.invalidPixKey": "Invalid Pix Key",
  "form.formValidate.inputAccountNumber": "Please input your account number",
  "form.formValidate.accountDigitLen12":
    "The account digit field should be 1 to 2 characters",
  "form.formValidate.inputAccountDigit": "Please input your account digit",
  "form.formValidate.accountDigitLen15":
    "The account digit field should be 1 to 5 characters",
  "form.formValidate.inputBankBranch": "Please input your bank branch",
  "form.formValidate.nameLen5_100": "name character length 5-100",
  "form.formValidate.name": "Name",
  "form.formValidate.keyType": "Key Type",
  "form.formValidate.pixType": "Pix Type",
  "form.formValidate.email": "E-mail",
  "form.formValidate.cellphone": "Cellphone",
  "form.formValidate.account": "Account",
  "form.formValidate.bank": "Bank",
  "form.formValidate.accountType": "Account Type",
  "form.formValidate.accountNumber": "Account Number",
  "form.formValidate.accountDigit": "Account digit",
  "form.formValidate.bankBranch": "Bank branch",
  "form.formValidate.bankDigit": "Bank digit",
  "form.formValidate.documentType": "Document type",
  "form.formValidate.documentId": "Id",
  "form.formValidate.paypalCountry": "Paypal country",
  "form.formValidate.paypalAccount": "Paypal account",
  "form.formValidate.rePaypalAccount": "Re-enter Paypal account",
  "form.formValidate.selectPaymentBank": "Please choose a payment bank",
  "form.notice.choosePaymentMethod": "Choose payment method",
};

const formValidateEs = {
  "form.chooseCountry": "Selecciona el país final",
  "form.missToken": "Parámetros faltantes!",
  "form.formSections.phone": "TELEFONE",
  "form.formSections.email": "EMAIL",
  "form.formSections.checking": "VERIFICANDO",
  "form.formSections.savings": "AHORROS",
  "form.formSections.debit": "DÉBITO",
  "form.formValidate.invalidName": "Nombre inválido",
  "form.formValidate.selectBank": "Seleccione un banco",
  "form.formValidate.selectDocumentType": "Seleccione un tipo de documento",
  "form.formValidate.invalidId": "Identificación inválido",
  "form.formValidate.selectAccountType": "Seleccione un tipo de cuenta",
  "form.formValidate.invalidAccount": "Cuenta no válida",
  "form.formValidate.invalidEmailAddress":
    "Dirección de correo electrónico inválida",
  "form.formValidate.inputEmail": "Por favor ingrese su correo electrónico",
  "form.formValidate.inputCellphone": "Ingrese un teléfono celular",
  "form.formValidate.invalidCellphone": "Teléfono celular inválido",
  "form.formValidate.inputCpfOrCnpj": "Ingrese su cpf o cnpj",
  "form.formValidate.invalidCpfOrCnpj": "Nombre CPF/CNPJ",
  "form.formValidate.selectCountry": "Por favor, selecione um país",
  "form.formValidate.inconsistent": "As contas são diferentes",
  "form.formValidate.selectKeyType": "Seleccione un tipo de clave",
  "form.formValidate.fillPixKey": "Por favor complete la clave Pix",
  "form.formValidate.invalidPixKey": "Clave de Pix no válida",
  "form.formValidate.inputAccountNumber": "Por favor ingrese su cuenta",
  "form.formValidate.accountDigitLen12":
    "El campo del dígito de la cuenta debe tener entre 1 y 2 caracteres",
  "form.formValidate.inputAccountDigit": "Ingrese el dígito de su cuenta",
  "form.formValidate.accountDigitLen15":
    "El campo del dígito de la cuenta debe tener entre 1 y 5 caracteres",
  "form.formValidate.inputBankBranch": "Por favor ingrese su sucursal bancaria",
  "form.formValidate.nameLen5_100": "longitud del carácter del nombre 5-100",
  "form.formValidate.name": "Nombre",
  "form.formValidate.keyType": "Tipo de clave",
  "form.formValidate.pixType": "Pix Type",
  "form.formValidate.email": "Correo electrónico",
  "form.formValidate.cellphone": "Teléfono móvil",
  "form.formValidate.account": "Cuenta",
  "form.formValidate.bank": "Banco",
  "form.formValidate.accountType": "Tipo de cuenta",
  "form.formValidate.accountNumber": "Número de cuenta",
  "form.formValidate.accountDigit": "Dígito de la cuenta",
  "form.formValidate.bankBranch": "Sucursal bancaria",
  "form.formValidate.bankDigit": "Dígito de la sucursal",
  "form.formValidate.documentType": "Tipo de Documento",
  "form.formValidate.documentId": "Identificación",
  "form.formValidate.paypalCountry": "País da conta Paypal",
  "form.formValidate.paypalAccount": "Conta Paypal",
  "form.formValidate.rePaypalAccount": "Reinsira a conta Paypal",
  "form.formValidate.selectPaymentBank": "Por favor elige un banco de pago",
  "form.notice.choosePaymentMethod": "Escoge el método de pago",
};

const formValidatePt = {
  "form.chooseCountry": "Escolha o país final",
  "form.missToken": "Parâmetros ausentes!",
  "form.formSections.phone": "TELÉFONO",
  "form.formSections.email": "O EMAIL",
  "form.formSections.checking": "VERIFICANDO",
  "form.formSections.savings": "POUPANÇA",
  "form.formSections.debit": "DÉBITO",
  "form.formValidate.invalidName": "Nome inválido",
  "form.formValidate.selectBank": "Selecione um banco",
  "form.formValidate.selectDocumentType": "Selecione um tipo de documento",
  "form.formValidate.invalidId": "Identidade inválido",
  "form.formValidate.selectAccountType": "Selecione um tipo de conta",
  "form.formValidate.invalidAccount": "Conta inválida",
  "form.formValidate.invalidEmailAddress": "Por favor, preencha a chave Pix",
  "form.formValidate.inputEmail": "Por favor insira seu e-mail",
  "form.formValidate.inputCellphone": "Por favor insira um telefone celular",
  "form.formValidate.invalidCellphone": "Celular inválido",
  "form.formValidate.inputCpfOrCnpj": "Insira seu cpf ou cnpj",
  "form.formValidate.invalidCpfOrCnpj": "Nome CPF/CNPJ",
  "form.formValidate.selectCountry": "Por favor seleccione un país",
  "form.formValidate.inconsistent": "La cuentas son inconsistentes",
  "form.formValidate.selectKeyType": "Selecione um tipo de chave",
  "form.formValidate.fillPixKey": "Por favor, preencha a chave Pix",
  "form.formValidate.invalidPixKey": "Chave Pix inválida",
  "form.formValidate.inputAccountNumber": "Por favor insira sua conta",
  "form.formValidate.accountDigitLen12":
    "O campo de dígitos da conta deve ter de 1 a 2 caracteres",
  "form.formValidate.inputAccountDigit":
    "Por favor insira o dígito da sua conta",
  "form.formValidate.accountDigitLen15":
    "O campo de dígitos da conta deve ter de 1 a 5 caracteres",
  "form.formValidate.inputBankBranch": "Insira sua agência bancária",
  "form.formValidate.nameLen5_100": "nome comprimento de caracteres 5-100",
  "form.formValidate.name": "Nome",
  "form.formValidate.keyType": "Tipo de chave",
  "form.formValidate.pixType": "Chave Pix",
  "form.formValidate.email": "Email",
  "form.formValidate.cellphone": "Celular",
  "form.formValidate.account": "Conta",
  "form.formValidate.bank": "Banco",
  "form.formValidate.accountType": "Tipo de conta",
  "form.formValidate.accountNumber": "Número de conta",
  "form.formValidate.accountDigit": "Número da conta",
  "form.formValidate.bankBranch": "Agência bancária",
  "form.formValidate.bankDigit": "Dígito da filial",
  "form.formValidate.documentType": "Tipo de documento",
  "form.formValidate.documentId": "Identidade",
  "form.formValidate.paypalCountry": "País de la cuenta Paypal",
  "form.formValidate.paypalAccount": "Cuenta de Paypal",
  "form.formValidate.rePaypalAccount":
    "Vuelva a ingresar a la cuenta de Paypal",
  "form.formValidate.selectPaymentBank": "Escolha um banco de pagamento",
  "form.notice.choosePaymentMethod": "Escolha o método de pagamento",
};

export { formValidateEn, formValidateEs, formValidatePt };
