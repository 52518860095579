import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Notice from "../../components/notice/Notice";
import {
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
  walletLabelMap,
} from "../../utils/config";
import { setTradeForm, setTradeFormDetail } from "../../redux/actions";

import speiImg from "../../images/spei.png";
import pixImg from "../../images/pix.png";
import paypalImg from "../../images/paypal.png";
import nequiImg from "../../images/nequi.png";
import banktranferImg from "../../images/banktranfer.png";
import transfiyaImg from "../../images/transfiya.png";
import "./MethodPage.scss";

function MethodPage(props) {
  // console.log("MethodPage-props", props);
  const { t } = useTranslation();
  const token = getQueryVariable("t");
  const history = useHistory();
  const [methodList, setMethodList] = useState([]);
  const [selectNotice, setSelectNotice] = useState(false);
  const initRadioValue =
    props.tradeForm?.method ||
    getSessionStorage("cacheFilledData")?.method ||
    "";
  const [radioValue, setRadioValue] = useState(initRadioValue);
  const [expired, setExpired] = useState(true);

  useEffect(() => {
    const remain = props.tradeInfo.order_detail.remain_time * 1000 - Date.now();
    if (remain > 0) {
      setExpired(false);
    } else {
      setExpired(true);
    }
  }, [props]);

  const getExistingMethod = useCallback(() => {
    if (
      props.tradeForm.country ||
      getSessionStorage("cacheFilledData")?.country
    ) {
      props.tradeInfo.form_list.some((m) => {
        if (
          props.tradeForm.country === m.country ||
          getSessionStorage("cacheFilledData")?.country === m.country
        ) {
          const res = m.method.map((item) => {
            if (item.name === "WALLET") {
              item.name = walletLabelMap[m.country];
            }
            return item;
          });
          setMethodList(res);
          return true;
        }
        return "";
      });
    } else {
      history.push("/?t=" + token);
    }
  }, [props, history, token]);

  useEffect(() => {
    getExistingMethod();
  }, [getExistingMethod]);

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSubmit = () => {
    if (expired) return;
    if (!radioValue) {
      setSelectNotice(true);
    } else {
      const _country =
        props.tradeForm.country || getSessionStorage("cacheFilledData").country;
      if (_country && radioValue) {
        let temp = "";
        if (_country === "BRA") {
          if (radioValue === "WALLET") {
            temp = `${_country}-${radioValue}-USD`;
          } else {
            temp = `${_country}-${radioValue}-BRL`;
          }
        } else if (_country === "MEX") {
          if (radioValue === "WALLET") {
            temp = `${_country}-${radioValue}-USD`;
          } else {
            temp = `${_country}-${radioValue}-MXN`;
          }
        } else if (_country === "GLOBAL") {
          temp = `${_country}-${radioValue}-USD`;
        } else if (_country === "COL") {
          temp = `${_country}-${radioValue}-COP`;
        }
        props.setTradeFormDetail(props.tradeInfo.fee_list[temp]);
        setSessionStorage("cacheFilledData", {
          ...getSessionStorage("cacheFilledData"),
          tradeFormDetail: props.tradeInfo.fee_list[temp],
        });
      } else if (
        getSessionStorage("cacheFilledData")?.country &&
        getSessionStorage("cacheFilledData")?.method
      ) {
        // 页面刷新从缓存数据中获取
        let temp = "";
        if (getSessionStorage("cacheFilledData")?.country === "BRA") {
          if (getSessionStorage("cacheFilledData")?.method === "WALLET") {
            temp = `${getSessionStorage("cacheFilledData")?.country}-${
              getSessionStorage("cacheFilledData")?.method
            }-USD`;
          } else {
            temp = `${getSessionStorage("cacheFilledData")?.country}-${
              getSessionStorage("cacheFilledData")?.method
            }-BRL`;
          }
        } else if (getSessionStorage("cacheFilledData")?.country === "MEX") {
          temp = `${getSessionStorage("cacheFilledData")?.country}-${
            getSessionStorage("cacheFilledData")?.method
          }-MXN`;
        } else if (getSessionStorage("cacheFilledData")?.country === "GLOBAL") {
          temp = `${getSessionStorage("cacheFilledData")?.country}-${
            getSessionStorage("cacheFilledData")?.method
          }-USD`;
        } else if (getSessionStorage("cacheFilledData")?.country === "COL") {
          temp = `${getSessionStorage("cacheFilledData")?.country}-${
            getSessionStorage("cacheFilledData")?.method
          }-COP`;
        }
        props.setTradeForm({
          ...props.tradeForm,
          method: radioValue,
        });
        props.setTradeFormDetail(props.tradeInfo.fee_list[temp]);
        setSessionStorage("cacheFilledData", {
          ...getSessionStorage("cacheFilledData"),
          tradeFormDetail: props.tradeInfo.fee_list[temp],
        });
      }
      props.setTradeForm({
        ...props.tradeForm,
        infomation: {},
      });
      setSessionStorage("cacheFilledData", {
        ...getSessionStorage("cacheFilledData"),
        method: radioValue,
      });
      history.push("/?t=" + token);
    }
  };

  return (
    <>
      <Notice
        show={selectNotice}
        text={t("form.notice.choosePaymentMethod")}
        setNoticeHide={() => setSelectNotice(false)}
      />
      <div className="method-wrap">
        <Header back={true} />
        <div className="method-box">
          <div className="method-group">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={radioValue}
                onChange={handleChange}
              >
                {methodList.map((item) => (
                  <div className="method-item" key={item.name}>
                    <FormControlLabel
                      value={item.value}
                      control={<Radio />}
                      label={item.name}
                    />
                    {item.name === "SPEI" && <img src={speiImg} alt="" />}
                    {item.name === "PIX" && (
                      <img src={pixImg} className="pix-img" alt="" />
                    )}
                    {item.name === "PAYPAL" && (
                      <img src={paypalImg} className="paypal-img" alt="" />
                    )}
                    {item.name === "NEQUI" && (
                      <img src={nequiImg} className="paypal-img" alt="" />
                    )}
                    {item.name === "BANKTRANSFER" && (
                      <img
                        src={banktranferImg}
                        className="banktranfer-img"
                        alt=""
                      />
                    )}
                    {item.name === "TRANSFIYA" && (
                      <img src={transfiyaImg} className="paypal-img" alt="" />
                    )}
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <button type="submit" className="submit-btn" onClick={handleSubmit}>
          submit
        </button>
        <Footer timeFlag={true} />
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTradeForm: (item) => dispatch(setTradeForm(item)),
    setTradeFormDetail: (item) => dispatch(setTradeFormDetail(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MethodPage);
