import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";
import FlipMove from "react-flip-move";
import uuid from "react-uuid";
import { getQueryVariable, getSessionStorage } from "../../utils/config";

import InfoLogoM from "../../images/信息2.png";
import selectM from "../../images/select_icon2.png";
import pagsmileLogoM from "../../images/pagsmile_logo2.png";
import backIMG from "../../images/back-arrow.png";

import "./Header.scss";

function Header(props) {
  const token = getQueryVariable("t");
  const history = useHistory();
  const { i18n } = useTranslation();
  const [dropDownState, setDropDpwnState] = useState({
    state: false,
    value: "main",
  });

  const codeToLang = {
    es: "Español",
    en: "English",
    pt: "Português",
  };
  return (
    <div>
      <div className="headerMobile">
        <div className="headerMobile__top">
          <img className="headerMobile__pagsmile" src={pagsmileLogoM} alt="" />
        </div>
        <div className="headerMobile__bottom">
          {props.back ? (
            <img
              className="back-arrow"
              src={backIMG}
              alt=""
              onClick={() => {
                history.push("/?t=" + token);
              }}
            />
          ) : (
            <div
              className="header--langContainer"
              onClick={() => {
                setDropDpwnState((prev) => ({
                  state: true,
                  value: "lang",
                }));
              }}
            >
              <span className="header__langTextSelect">
                {codeToLang[getSessionStorage("lng") || i18n.language]}
              </span>
              <img className="header__selectIcon" src={selectM} alt="" />
            </div>
          )}

          <img
            className="headerMobile__info"
            src={InfoLogoM}
            alt=""
            onClick={() => {
              setDropDpwnState((prev) => ({
                state: true,
                value: "main",
              }));
            }}
          />
        </div>
      </div>
      <FlipMove
        enterAnimation="accordionVertical"
        leaveAnimation="accordionVertical"
      >
        {dropDownState.state && (
          <DropDownMenu
            back={() => {
              setDropDpwnState((prev) => ({
                ...prev,
                state: false,
              }));
            }}
            state={dropDownState.value}
            key={uuid()}
          />
        )}
      </FlipMove>
    </div>
  );
}

export default Header;
