import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import { debounce } from "lodash";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Notice from "../../components/notice/Notice";
import { Formik, Form } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import { setTradeForm, setShowPolicy } from "../../redux/actions";
import PrivacyPolicy from "../homePage/PrivacyPolicy";

import FormInput from "../../package/form/FormInput";
import FormSelect from "../../package/form/FormSelect";
import * as config from "./formConfig";
import {
  transfiyaBankConfig,
  getQueryVariable,
  setSessionStorage,
  getSessionStorage,
  getPhoneAreaCode,
  notify,
} from "../../utils/config";

import $axios from "../../utils/http";
import "./ReceiverInfoPage.scss";

const classNames = require("classnames");

function ReceiverInfoPage(props) {
  console.log("ReceiverInofPage-props", props);
  const { t } = useTranslation();
  const country =
    props.tradeForm?.country || getSessionStorage("cacheFilledData")?.country;
  const method =
    props.tradeForm?.method || getSessionStorage("cacheFilledData")?.method;
  const propsInfamation = props.tradeForm?.infomation;
  const token = getQueryVariable("t");
  const history = useHistory();
  // state
  const [isChecked, setIsChecked] = useState(true);
  const [paypalCountryOptions, setPaypalCountryOptions] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [transfiyaBankOptions, setTransfiyaBankOptions] = useState([]);
  const [transfiyaBankAccount, setTransfiyaBankAccount] = useState("");
  const [cacheTransfiyaBankList, setCacheTransfiyaBankList] = useState([]);
  const [noticeShow, setNoticeShow] = useState(false);

  const handleChangePrivacy = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleShowPolicy = () => {
    props.setShowPolicy(true);
  };

  if (!method) {
    history.push("/?t=" + token);
  }

  // 获取global paypal的国家列表
  const getPaypalCountryList = useCallback(() => {
    $axios({
      url: "/api/internal/checkout/paypal/countries",
      method: "POST",
    }).then((res) => {
      if (res.code === 200) {
        setPaypalCountryOptions(res.data);
        const cacheFilledData = getSessionStorage("cacheFilledData");
        const _data = {
          ...cacheFilledData,
          countryList: res.data,
        };
        setSessionStorage("cacheFilledData", JSON.stringify(_data));
      }
    });
  }, []);

  useEffect(() => {
    getPaypalCountryList();
  }, [getPaypalCountryList]);

  const accountTypeOptions = [
    { name: t("form.formSections.checking"), value: "CHECKING" },
    { name: t("form.formSections.savings"), value: "SAVINGS" },
  ];
  const pixKeyTypeOptions = [
    { name: "CPF", value: "CPF" },
    { name: "CNPJ", value: "CNPJ" },
    { name: t("form.formSections.phone"), value: "PHONE" },
    { name: t("form.formSections.email"), value: "EMAIL" },
    { name: "EVP", value: "EVP" },
  ];
  const speiAccountTypeOptions = [
    { name: t("home.formSections.debit"), value: "DEBIT" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: "CLABE", value: "CLABE" },
  ];

  const speiDocumentTypeOptions = [
    { name: "RFC", value: "RFC" },
    { name: "CURP", value: "CURP" },
  ];

  const transfiyaDocumentTypeOptions = [
    { name: "CC", value: "CC" },
    { name: "CE", value: "CE" },
    { name: "PEP", value: "PEP" },
  ];

  const closePolicy = () => {
    props.setShowPolicy(false);
  };

  const addSelectedMarkToBank = useCallback(() => {
    const bankAccount = props.tradeForm?.bankInfo?.bankAccount;
    const paymentImgs = document.querySelectorAll(".bank-payment-img");
    if (paymentImgs.length < 1) {
      setTimeout(() => {
        addSelectedMarkToBank();
      }, 100);
      return false;
    }

    for (let j = 0; j < paymentImgs.length; j++) {
      const text = paymentImgs[j].parentElement.lastChild.innerHTML;
      if (text === bankAccount) {
        paymentImgs[j].classList.add("active");
        break;
      }
    }
  }, [props]);

  const [bankOptions, setBankOptions] = useState([]);
  useEffect(() => {
    props.tradeInfo.form_list.some((m) => {
      if (
        props.tradeForm.country === m.country ||
        getSessionStorage("cacheFilledData")?.country === m.country
      ) {
        m.method.some((b) => {
          if (
            b.name === props.tradeForm.method ||
            b.name === getSessionStorage("cacheFilledData")?.method
          ) {
            if (b.name === "TRANSFIYA") {
              const bankAccount = props.tradeForm?.bankInfo?.bankAccount;
              const bankSupportedList =
                props.tradeForm?.bankInfo?.bankSupportedList;
              if (bankAccount && bankSupportedList) {
                // 有缓存
                setTransfiyaBankOptions(bankSupportedList);
                setTransfiyaBankAccount(bankAccount);
                addSelectedMarkToBank();
              } else {
                const bankList = b.bank_list.map((item) => {
                  item.bankAccount = "";
                  return item;
                });
                setTransfiyaBankOptions(bankList);
                setCacheTransfiyaBankList(bankList);
              }
            } else {
              setBankOptions(b.bank_list);
            }
            return true;
          }
          return "";
        });
      }
      return "";
    });
  }, [props, addSelectedMarkToBank]);

  const resetColBankList = () => {
    const paymentImgs = document.querySelectorAll(".bank-payment-img");
    if (paymentImgs.length < 1) {
      setTimeout(() => {
        resetColBankList();
      }, 1000);
      return false;
    }
    for (let j = 0; j < paymentImgs.length; j++) {
      paymentImgs[j].setAttribute(
        "class",
        paymentImgs[j].getAttribute("class").replace("active", "")
      );
    }
  };

  const handleChooseTransfiyaBank = (e) => {
    const targetElement = e.currentTarget;
    const paymentImgs = document.querySelectorAll(".bank-payment-img");
    if (targetElement.classList.contains("supported")) {
      for (let j = 0; j < paymentImgs.length; j++) {
        paymentImgs[j].setAttribute(
          "class",
          paymentImgs[j].getAttribute("class").replace("active", "")
        );
      }
      const bankAcccount =
        targetElement.parentElement.lastElementChild.innerHTML;
      targetElement.classList.add("active");
      setTransfiyaBankAccount(bankAcccount);
    }
  };

  const processedTransfiyaBankData = (supportedBankData) => {
    let count = 0;
    const _transfiyaBankList = JSON.parse(
      JSON.stringify(cacheTransfiyaBankList)
    );
    const processedBankData = _transfiyaBankList
      .map((temp) => {
        temp.id = 999;
        return temp;
      })
      .map((item) => {
        supportedBankData.forEach((per) => {
          if (item.value === per.bank_code) {
            item.bankAccount = per.bank_number;
            item.id = count++;
          }
        });
        return item;
      })
      .sort((a, b) => a.id - b.id)
      .filter((data) => data.id !== 999);
    return processedBankData;
  };

  const getSupportedTransfiyaBankList = debounce((phoneNumber) => {
    setLoadingOverlay(true);
    $axios({
      url: "/api/internal/checkout/account_list",
      method: "POST",
      data: {
        account_type: "PHONE",
        account: phoneNumber,
      },
      headers: {
        "Accept-Language": getSessionStorage("lng") || "pt",
      },
    })
      .then((res) => {
        if (res.code === 200) {
          const resData = res.data;
          if (!resData) {
            setTransfiyaBankAccount("");
            setTransfiyaBankOptions(cacheTransfiyaBankList);
            setTimeout(() => {
              resetColBankList();
            }, 1000);
            return false;
          }
          if (resData.length < 1) {
            setTransfiyaBankAccount("");
            setTransfiyaBankOptions(cacheTransfiyaBankList);
            setTimeout(() => {
              resetColBankList();
            }, 1000);
            return false;
          }

          const bankData = processedTransfiyaBankData(resData);
          setTransfiyaBankOptions(bankData);
        }
      })
      .catch((err) => {
        notify("error", err.msg);
      })
      .finally(() => {
        setLoadingOverlay(false);
      });
  }, 1000);

  const handleChangePhoneNumber = (e) => {
    const phoneNumber = e.target.value;
    if (/^(\+57|57)?\d{12,14}$/.test(phoneNumber)) {
      getSupportedTransfiyaBankList(phoneNumber);
    } else {
      setTransfiyaBankAccount("");
      setTransfiyaBankOptions(cacheTransfiyaBankList);
      setTimeout(() => {
        resetColBankList();
      }, 1000);
    }
  };

  const [expired, setExpired] = useState(true);
  useEffect(() => {
    const remain = props.tradeInfo.order_detail.remain_time * 1000 - Date.now();
    // console.log("remain", remain);
    if (remain > 0) {
      setExpired(false);
    } else {
      setExpired(true);
    }
  }, [props]);

  const handleFormSubmitValidate = (props) => {
    // console.log("submit123", expired);
    if (expired) return;
    props.submitForm();
  };

  const [domMount, setDomMount] = useState(false);
  useEffect(() => {
    setDomMount(true);
  }, []);

  const submitFormBtnPortal = useCallback(
    (cb) => {
      return (
        domMount &&
        ReactDOM.createPortal(
          <button type="button" className="submit-btn" onClick={cb}>
            submit
          </button>,
          document.querySelector(".confirm-box")
        )
      );
    },
    [domMount]
  );

  return (
    <>
      {props.showPolicy}
      {props.showPolicy && <PrivacyPolicy back={closePolicy} />}
      <Notice
        show={noticeShow}
        text={t("form.formValidate.selectPaymentBank")}
        setNoticeHide={() => setNoticeShow(false)}
      />
      <div className="receiver-info-wrap">
        <Header back={true} />
        <div
          className="receiver-info-box"
          style={{ bottom: !props?.showToggle ? "12rem" : "27rem" }}
        >
          <p className="title">
            {t("home_step3_t2")} {country}-{method}
          </p>
          {country === "BRA" && method === "BANKTRANSFER" && (
            <Formik
              initialValues={config.banktranferInitialValues(propsInfamation)}
              validationSchema={config.banktranferValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                values = {
                  ...values,
                  banktranferAccountType: config.lngTransform(
                    values.banktranferAccountType
                  ),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).name}
                      name="banktranferName"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).cpf_cnpj}
                      name="banktranferCpf_cnpj"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.banktranferLabelMap(t).bank}
                      name="banktranferBank"
                      require="required"
                      options={bankOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.banktranferBank}
                      error={props.errors.banktranferBank}
                      touched={props.touched.banktranferBank}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.banktranferLabelMap(t).account_type}
                      name="banktranferAccountType"
                      require="required"
                      options={accountTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.banktranferAccountType.indexOf(",") >= 0
                          ? props.values.banktranferAccountType.split(",")[1]
                          : props.values.banktranferAccountType
                      }
                      error={props.errors.banktranferAccountType}
                      touched={props.touched.banktranferAccountType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).account_number}
                      name="banktranferAccountNumber"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).account_digit}
                      name="banktranferAccountDigit"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).bank_branch}
                      name="banktranferBankBranch"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).branch_digit}
                      name="banktranferBranchDigit"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).email}
                      name="banktranferEmail"
                      require="required"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.banktranferLabelMap(t).cellphone}
                      name="banktranferCellphone"
                      require="required"
                    />
                  </div>
                  {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                </Form>
              )}
            </Formik>
          )}
          {country === "BRA" && method === "PIX" && (
            <Formik
              initialValues={config.pixInitialValues(propsInfamation)}
              validationSchema={config.pixValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                if (
                  values.pixKeyType === "PHONE" ||
                  values.pixKeyType === "TELÉFONO" ||
                  values.pixKeyType === "TELEFONE"
                ) {
                  const phone = getPhoneAreaCode(country, values.pixKey);
                  values = { ...values, pixKey: phone };
                }
                values = {
                  ...values,
                  pixKeyType: config.lngTransform(values.pixKeyType),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).name}
                      name="pixName"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).cpf_cnpj}
                      name="pixCpf_cnpj"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.pixLabelMap(t).key_type}
                      name="pixKeyType"
                      require="required"
                      options={pixKeyTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.pixKeyType.indexOf(",") >= 0
                          ? props.values.pixKeyType.split(",")[1]
                          : props.values.pixKeyType
                      }
                      error={props.errors.pixKeyType}
                      touched={props.touched.pixKeyType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).pix_key}
                      name="pixKey"
                      require="required"
                      id={props.values.pixKeyType}
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).email}
                      name="pixEmail"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.pixLabelMap(t).cellphone}
                      name="pixCellphone"
                      require="required"
                      type="text"
                    />
                  </div>
                  {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                </Form>
              )}
            </Formik>
          )}
          {(country === "BRA" || country === "MEX") && method === "WALLET" && (
            <Formik
              initialValues={config.walletInitialValues(propsInfamation)}
              validationSchema={config.walletValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).name}
                      name="walletName"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).cpf_cnpj}
                      name="walletCpf_cnpj"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).account}
                      name="walletAccount"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).email}
                      name="walletEmail"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.walletLabelMap(t).cellphone}
                      name="walletCellphone"
                      require="required"
                      type="text"
                    />
                  </div>
                  {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                </Form>
              )}
            </Formik>
          )}
          {country === "MEX" && method === "SPEI" && (
            <Formik
              initialValues={config.speiInitialValues(propsInfamation)}
              validationSchema={config.speiValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                values = {
                  ...values,
                  speiAccountType: config.lngTransform(values.speiAccountType),
                };
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).name}
                      name="speiName"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).bank}
                      name="speiBank"
                      require="required"
                      options={bankOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.speiBank}
                      error={props.errors.speiBank}
                      touched={props.touched.speiBank}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).document_type}
                      name="speiDocumentType"
                      require="required"
                      options={speiDocumentTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={props.values.speiDocumentType}
                      error={props.errors.speiDocumentType}
                      touched={props.touched.speiDocumentType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).document_id}
                      name="speiDocumentId"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormSelect
                      label={config.speiLabelMap(t).account_type}
                      name="speiAccountType"
                      require="required"
                      options={speiAccountTypeOptions}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      value={
                        props.values.speiAccountType.indexOf(",") >= 0
                          ? props.values.speiAccountType.split(",")[1]
                          : props.values.speiAccountType
                      }
                      error={props.errors.speiAccountType}
                      touched={props.touched.speiAccountType}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).account}
                      name="speiAccount"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).email}
                      name="speiEmail"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.speiLabelMap(t).cellphone}
                      name="speiCellphone"
                      require="required"
                    />
                  </div>
                  {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                </Form>
              )}
            </Formik>
          )}
          {country === "GLOBAL" && method === "WALLET" && (
            <Formik
              initialValues={config.paypalInitialValues(propsInfamation)}
              validationSchema={config.paypalValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="form-item-box">
                    <FormSelect
                      label={config.globalPaypalLabelMap(t).country}
                      name="paypalCountry"
                      require="required"
                      options={paypalCountryOptions}
                      value={props.values.paypalCountry}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                      error={props.errors.paypalCountry}
                      touched={props.touched.paypalCountry}
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.globalPaypalLabelMap(t).account}
                      name="paypalAccount"
                      require="required"
                      type="text"
                    />
                  </div>
                  <div className="form-item-box">
                    <FormInput
                      label={config.globalPaypalLabelMap(t).confirmAccount}
                      name="paypalConfirmAccount"
                      require="required"
                      type="text"
                    />
                  </div>
                  {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                </Form>
              )}
            </Formik>
          )}
          {country === "COL" && method === "TRANSFIYA" && (
            <Formik
              initialValues={config.transfiyaInitialValues(propsInfamation)}
              validationSchema={config.transfiyaValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                // if (transfiyaBankOptions.length > 0 && !transfiyaBankAccount) {
                //   setNoticeShow(true);
                //   return false;
                // }
                if (!isChecked) {
                  return false;
                }
                // 缓存, 供下单页展示用
                const bankInfo = {
                  bankAccount: transfiyaBankAccount,
                  bankSupportedList: transfiyaBankOptions,
                };
                props.setTradeForm({
                  ...props.tradeForm,
                  infomation: values,
                  bankInfo,
                });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                  bankInfo,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <>
                    <div className="form-item-box">
                      <FormInput
                        label={config.colTransfiyaLabelMap(t).name}
                        name="name"
                        require="required"
                        type="text"
                      />
                    </div>
                    <FormInput
                      label={config.colTransfiyaLabelMap(t).phoneNumber}
                      name="phoneNumber"
                      require="required"
                      type="text"
                      onInput={handleChangePhoneNumber}
                    />
                    <div className="form-item-box"></div>
                    <div>
                      <p className="choose-bank">
                        {t("form.formValidate.selectPaymentBank")}:
                      </p>
                      <LoadingOverlay active={loadingOverlay} spinner>
                        <div className="bank-payment-img-box">
                          {transfiyaBankOptions.map((item) => (
                            <div
                              className="bank-payment-img-item"
                              key={item.value}
                            >
                              <div
                                className={classNames("bank-payment-img", {
                                  supported: !!item.bankAccount,
                                })}
                                onClick={handleChooseTransfiyaBank}
                              >
                                <img
                                  className={classNames({
                                    supported: !!item.bankAccount,
                                  })}
                                  alt="transfiya bank"
                                  src={transfiyaBankConfig()[item.value]}
                                />
                              </div>
                              <p
                                className={classNames("bank-name", {
                                  supported: !!item.bankAccount,
                                })}
                              >
                                {item.name}
                              </p>
                              <p
                                className={classNames("bank-account", {
                                  supported: !!item.bankAccount,
                                })}
                              >
                                {item.bankAccount}
                              </p>
                            </div>
                          ))}
                        </div>
                      </LoadingOverlay>
                    </div>
                    <div className="form-item-box">
                      <FormSelect
                        label={config.colTransfiyaLabelMap(t).documentType}
                        name="documentType"
                        require="required"
                        options={transfiyaDocumentTypeOptions}
                        value={props.values.documentType}
                        onChange={props.setFieldValue}
                        onBlur={props.setFieldTouched}
                        error={props.errors.documentType}
                        touched={props.touched.documentType}
                      />
                    </div>
                    <div className="form-item-box">
                      <FormInput
                        label={config.colTransfiyaLabelMap(t).documentId}
                        name="documentId"
                        require="required"
                        type="text"
                      />
                    </div>
                    {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                  </>
                </Form>
              )}
            </Formik>
          )}
          {country === "COL" && method === "WALLET" && (
            <Formik
              initialValues={config.nequiInitialValues(propsInfamation)}
              validationSchema={config.nequiValidationSchema(country, t)}
              onSubmit={async (values, { setSubmitting }) => {
                if (!isChecked) {
                  return false;
                }
                props.setTradeForm({ ...props.tradeForm, infomation: values });
                setSessionStorage("cacheFilledData", {
                  ...getSessionStorage("cacheFilledData"),
                  infomation: values,
                });
                history.push("/?t=" + token);
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <>
                    <div className="form-item-box">
                      <FormInput
                        label={config.colNequiLabelMap(t).phoneNumber}
                        name="phoneNumber"
                        require="required"
                        type="text"
                      />
                    </div>
                    {submitFormBtnPortal(() => handleFormSubmitValidate(props))}
                  </>
                </Form>
              )}
            </Formik>
          )}
          <div className="checkbox-wrap">
            <Checkbox
              size="small"
              checked={isChecked}
              onChange={handleChangePrivacy}
            />
            <span className="privacy-box">
              {t("pricacy1")}{" "}
              <span className="privacy" onClick={handleShowPolicy}>
                {t("pricacy2")}
              </span>{" "}
              {t("pricacy3")}
            </span>
          </div>
          {!isChecked && (
            <p className="privacy-policy-error">{t("privacy_policy_error")}</p>
          )}
        </div>
        <Footer currencyFlag={true} />
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeForm: state.tradeForm,
    showPolicy: state.showPolicy,
    showToggle: state.showToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTradeForm: (item) => dispatch(setTradeForm(item)),
    setShowPolicy: (item) => dispatch(setShowPolicy(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiverInfoPage);
